import { useMemo } from 'react';

import { Form, InUse } from '@components';
import { useRouter, PageSection } from '@tripledotstudios/react-core';

import { FloatingLabeledInput, LabeledInput, LabeledSwitch } from '@components/resource';

import { PaymentsRoutes, EventsRoutes } from '@pages/routes';
import { useQuery } from '@hooks';
import { toOptions } from '@services/utils';
import BotSets from './form/BotSets';
import Ranks from './form/Ranks';

export default function ClientLeaderboardsForm({ resource, ...rest }) {
  const { query } = useRouter();

  const { response: gameItems } = useQuery(
    PaymentsRoutes.GameItems.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );

  const gameItemsOptions = useMemo(() => toOptions(
    gameItems?.items || [],
    {
      transform: (option, { backupGameItemsData }) => {
        // eslint-disable-next-line no-param-reassign
        option.backupGameItemsData = backupGameItemsData;

        return option;
      },
    },
  ), [gameItems?.items?.length]);

  const { response: objectiveTemplates } = useQuery(
    EventsRoutes.ObjectiveTemplates.indexRequest,
    { ...query, withoutPagination: true },
  );

  if (!gameItems?.items || !objectiveTemplates?.items) {
    return null;
  }

  const patchedResponse = {
    ...resource,
    data: {
      ...resource.data,
      activityTemplateId: resource.data.activityTemplateId || '',
    },
  };

  return (
    <Form.Resource resource={patchedResponse} {...rest}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" name="name" />
        <InUse.AvailabilityStateFormGroup floating />
      </Form.ControlsLayout>
      <PageSection title="Leaderboards configuration">
        <LabeledInput label="Number of bots" name="botsNumber" type="number" />
        <LabeledSwitch label="Win Flow Animation" name="winFlowAnimation" />
      </PageSection>
      <BotSets max={resource.data.maxBotSets} />
      <Ranks gameItemsOptions={gameItemsOptions} />
    </Form.Resource>
  );
}
