import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';
import { useExtendedFieldArray, useMoveArrayFieldPosition, useFormPermissions } from '@hooks';
import { Button, Form } from '@components';
import {
  Label,
  LabeledInput,
  LabeledSelect,
  RegisteredError,
  PositionControl,
} from '@components/resource';
import Rewards from '@components/events/Rewards';
import { useWatch } from 'react-hook-form';

function CardTitle({ itemPath }) {
  const [assetKey, position] = useWatch({ name: [`${itemPath}.assetKey`, `${itemPath}.position`] });

  return assetKey ? `Card ${position} - ${assetKey}` : `Card ${position}`;
}

const DEFAULT_WEIGHT = 1;

export default function Cards({ gameItemsOptions, rarityOptions }) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const formGroupName = generateName('cardsAttributes');

  const {
    fields,
    append,
    replace,
    handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const {
    sortedFields,
    increasePosition,
    decreasePosition,
    move,
  } = useMoveArrayFieldPosition({ fields, replace, formGroupName });

  const appendCard = () => {
    append({
      assetKey: '',
      position: increasePosition(),
      rarity: null,
      customWeight: DEFAULT_WEIGHT,
      duplicateConversionsAttributes: [],
      rewardsAttributes: [],
    });
  };

  const deleteCard = (field, index) => {
    decreasePosition(field);

    handleDelete(field, index);
  };

  return (
    <>
      <Label label={<span className="h4">Cards</span>} />
      <RegisteredError name="base" />
      {sortedFields.map((field, index) => {
        if (field._destroy) {
          return null;
        }

        const itemPath = `${formGroupName}.${index}`;

        return (
          <FormGroup key={[field.key, field.position].join('_')} name={itemPath}>
            <PageSection
              title={<CardTitle itemPath={itemPath} />}
              actions={!readOnly && (
                <div className="grid align-items-center gap-0" style={{ '--bs-columns': 2 }}>
                  <PositionControl
                    onUp={() => move('up', field.key)}
                    onDown={() => move('down', field.key)}
                    disableUp={field.position === 1}
                    disableDown={field.position === fields.length}
                  />
                  <Button.Delete
                    className="d-block align-self-center"
                    onClick={() => deleteCard(field, index)}
                  />
                </div>
              )}
            >
              <RegisteredError name="base" />
              <LabeledInput label="Position" sizes={[3, 9]} name="position" readOnly disabled type="number" />
              <LabeledInput label="Asset key" sizes={[3, 9]} name="assetKey" />
              <LabeledSelect label="Rarity" sizes={[3, 9]} name="rarity" options={rarityOptions} />
              <Form.When fullName="allowCustomWeight" value>
                <LabeledInput
                  label="Custom weight"
                  sizes={[3, 9]}
                  name="customWeight"
                  type="number"
                />
              </Form.When>
              <Rewards
                label="Duplicate conversion"
                formGroupName="duplicateConversionsAttributes"
                gameItemsOptions={gameItemsOptions}
                withBackupItems={false}
              />
              <Rewards
                label="Rewards"
                formGroupName="rewardsAttributes"
                gameItemsOptions={gameItemsOptions}
              />
            </PageSection>
          </FormGroup>
        );
      })}
      {!readOnly ? <Button.Add title="Add Card" className="mt-2" onClick={appendCard} /> : null}
    </>
  );
}
