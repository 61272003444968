import { useMemo } from 'react';
import { groupBy } from 'lodash';

import { Form, InUse } from '@components';
import { useRouter, PageSection } from '@tripledotstudios/react-core';

import { FloatingLabeledInput, LabeledCheckbox } from '@components/resource';
import Rewards from '@components/events/Rewards';

import { PaymentsRoutes, EventsRoutes } from '@pages/routes';
import { useQuery } from '@hooks';
import { toOptions } from '@services/utils';
import GachaChests from './form/GachaChests';
import Cards from './form/Cards';

export default function CollectionSetForm({ resource, ...rest }) {
  const { query } = useRouter();

  const { response: gachaChests } = useQuery(
    EventsRoutes.GachaChests.indexRequest,
    { ...query, withoutPagination: true },
  );

  const { response: gameItems } = useQuery(
    PaymentsRoutes.GameItems.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );

  const tierChestOptions = useMemo(() => groupBy(
    toOptions(
      gachaChests?.items || [],
      {
        transform: (option, { tier }) => {
          // eslint-disable-next-line no-param-reassign
          option.tier = tier;

          return option;
        },
      },
    ),
    'tier',
  ), [gachaChests?.items?.length]);

  const gameItemsOptions = useMemo(() => toOptions(
    gameItems?.items || [],
    {
      transform: (option, { backupGameItemsData }) => {
        // eslint-disable-next-line no-param-reassign
        option.backupGameItemsData = backupGameItemsData;

        return option;
      },
    },
  ), [gameItems?.items?.length]);

  const { data: { rarityOptions } } = resource;

  if (!gachaChests?.items || !gameItems?.items) {
    return null;
  }

  return (
    <Form.Resource resource={resource} {...rest}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" name="name" />
        <InUse.AvailabilityStateFormGroup floating />
      </Form.ControlsLayout>
      <LabeledCheckbox name="allowCustomWeight" label="Use custom weight for cards" className="mb-5" />
      <GachaChests tierChestOptions={tierChestOptions} />
      <Cards gameItemsOptions={gameItemsOptions} rarityOptions={rarityOptions} />
      <div className="pt-4">
        <PageSection title="Completion Reward">
          <Rewards
            label={null}
            sizes={[0, 12]}
            formGroupName="rewardsAttributes"
            gameItemsOptions={gameItemsOptions}
          />
        </PageSection>
      </div>
    </Form.Resource>
  );
}
