import { InputGroup } from 'react-bootstrap';
import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray, useFormPermissions } from '@hooks';
import { Button } from '@components';
import {
  LabeledInput, Label, Input, InputWithAddon,
} from '@components/resource';

export default function Bots({ max }) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const formGroupName = generateName('botsAttributes');

  const { fields, append, handleDelete } = useExtendedFieldArray({ name: formGroupName, keyName: 'key' });
  const appendNewBot = () => append({ minRange: null, maxRange: null });

  let botIndex = 0;

  return (
    <>
      <Label label={<span className="h4">Bots</span>} />
      {fields.map((field, index) => {
        if (field._destroy) return null;

        const itemPath = `${formGroupName}.${index}`;
        botIndex += 1;

        return (
          <FormGroup key={[field.key, index].join('_')} name={itemPath}>
            <PageSection
              title={`Bot ${botIndex}`}
              actions={!readOnly && (
                <Button.Delete
                  className="delete me-1"
                  title="Remove"
                  onClick={() => handleDelete(field, index)}
                />
              )}
            >
              <Input hidden name="position" value={botIndex} />
              <Label
                label="Bot speed reference"
                tooltipText="Bot speed value representing bot’s speed to reach single target.
                Defines how much faster is bot than player. Supports math formulas and “avg_win_time_20_lvl” attribute"
              >
                <InputWithAddon
                  name="botSpeedRefFormula"
                  after={(
                    <InputGroup.Text className="fs-4 fst-italic">
                      f
                      <span className="fs-6">x</span>
                    </InputGroup.Text>
                  )}
                />
              </Label>
              <LabeledInput
                label="Fallback bot speed reference"
                name="botSpeedRefFallback"
                type="number"
                tooltipText="In case of missing data for Bots speed reference,
                            this value will replace Bots speed reference value"
              />
              <LabeledInput label="Minimum range (%)" name="minRangePercent" type="number" />
              <LabeledInput label="Maximum range (%)" name="maxRangePercent" type="number" />
            </PageSection>
          </FormGroup>
        );
      })}
      {!readOnly && <Button.Add className="mt-2" onClick={appendNewBot} disabled={botIndex >= max} title="Add Bot" />}
    </>
  );
}
