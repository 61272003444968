import React from 'react';
import { Filters } from '@tripledotstudios/react-core/table';

import { AvailabilityStateFilter } from '@components/filters';
import { useEventTypesOptions } from '@pages/events/types/queries';

export default function Filter() {
  const { data: typeOptions } = useEventTypesOptions();

  return (
    <Filters.Container>
      <AvailabilityStateFilter />
      <Filters.Select label="Event type" name="typeIdIn" options={typeOptions} isMulti />
    </Filters.Container>
  );
}
