import { Table, Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { compact, get } from 'lodash';
import { PageSection, useRouter } from '@tripledotstudios/react-core';
import { EmptyList, Status } from '@components/collection';
import { Button, InUse, Form } from '@components';
import { InputWithAddon } from '@components/resource';

import { useQuery } from '@hooks';
import { AccountsRoutes, EventsRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';
import NewCardForm from './events/NewCardForm';

const Routes = AccountsRoutes.UserEvents;

const ConfigurationRow = ({ title, value }) => (
  <>
    <dt className="col-6">{title}</dt>
    <dd className="col-6">{value}</dd>
  </>
);

const CardQuantityForm = ({ id, quantity, refetch }) => {
  const { query } = useRouter();
  const canWrite = hasApplicationPermissions(['player', 'write']);

  const requestParams = {
    applicationId: query.applicationId,
    userId: query.id,
    id,
  };

  return (
    <Form defaultValues={{ quantity }} enableReinitialize>
      {({ setError }) => {
        const responseHandler = buildResourceResponseHandler({
          actionName: 'update',
          setError,
          onSuccess: refetch,
        });
        const onSubmit = (values) => (
          AccountsRoutes.PlayerCards.updateRequest({ ...requestParams, ...values }).then(responseHandler)
        );

        return (
          <Form.Component onSubmit={onSubmit}>
            <InputWithAddon
              readOnly={!canWrite}
              name="quantity"
              after={canWrite && <Button.Submit />}
              type="number"
            />
          </Form.Component>
        );
      }}
    </Form>
  );
};

function PlayerCardsTable({ occurrence, refetch }) {
  const { query } = useRouter();
  const collectionSetUrl = (collectionSetId) => (
    EventsRoutes.CollectionSets.editPath(
      {
        applicationId: query.applicationId,
        id: collectionSetId,
      },
    )
  );

  return (
    <PageSection title="Player Cards">
      <Table className="table-bordered table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Position</th>
            <th>Rarity</th>
            <th>Quantity</th>
            <th>Collection Set</th>
          </tr>
        </thead>
        <tbody>
          {occurrence.playerCards.map((card) => (
            <tr key={card.id}>
              <td>{card.id}</td>
              <td>{card.position}</td>
              <td>{card.rarity}</td>
              <td><CardQuantityForm {...card} refetch={refetch} /></td>
              <td>
                {card.collectionSet?.id && (
                <Link
                  to={collectionSetUrl(card.collectionSet.id)}
                  key={`card_${card.collectionSet.id}`}
                  target="_blank"
                >
                  {card.collectionSet.name}
                </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <NewCardForm occurrenceId={occurrence.id} refetch={refetch} />
    </PageSection>
  );
}

function ObjectivesTable({ occurrence }) {
  const { query } = useRouter();
  const objectiveConfigurationUrl = (objectiveConfigurationId) => (
    EventsRoutes.ObjectiveConfigurations.editPath(
      {
        applicationId: query.applicationId,
        id: objectiveConfigurationId,
      },
    )
  );
  return (
    <Table className="table-bordered table-striped">
      <thead>
        <tr>
          <th>Ordinal</th>
          <th>Start At</th>
          <th>End At</th>
          <th>Segment</th>
          <th>Objective</th>
        </tr>
      </thead>
      <tbody>
        {occurrence.objectives.map((objective, idx) => (
          <tr key={objective.id}>
            <td>{idx + 1}</td>
            <td>{objective.startAt}</td>
            <td>{objective.endAt}</td>
            <td>
              {objective.segment?.id && `#${objective.segment.id} - ${objective.segment.name}`}
            </td>
            <td>
              {objective.objectiveConfiguration?.id && (
              <Link
                to={objectiveConfigurationUrl(objective.objectiveConfiguration.id)}
                key={`objective_${objective.objectiveConfiguration.id}`}
                target="_blank"
              >
                {objective.objectiveConfiguration.name}
              </Link>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default function EventsView() {
  const { query } = useRouter();
  const requestParams = { userId: query.id, applicationId: query.applicationId };
  const { response, refetch } = useQuery(Routes.indexRequest, requestParams);
  const events = response?.items;
  const inProgressEventIds = events?.filter(({ inProgress }) => inProgress).map(({ id }) => id);

  const deallocate = async (event) => {
    const id = event.target.dataset.occurrenceId;

    Routes.deallocateRequest({ applicationId: query.applicationId, userId: query.id, id }).then(() => {
      refetch();
    });
  };

  const eventConfigurationUrl = (eventId) => (
    EventsRoutes.Configurations.editPath(
      {
        applicationId: query.applicationId,
        id: eventId,
      },
    )
  );

  return (
    <>
      <PageSection title="Events" />
      {events?.length > 0
        ? (
          <Accordion defaultActiveKey={inProgressEventIds} alwaysOpen>
            {events.map((event) => (
              <Accordion.Item eventKey={event.id} key={event.id}>
                <Accordion.Header>
                  <div className="me-1">
                    {compact([
                      `#${event.id}`,
                      `- ${event.name}`,
                      !event.recurring && `(Occurrence #${event.occurrences[0].id})`,
                    ]).join(' ')}
                  </div>
                  <Status value={event.state} className="me-1" />
                  <InUse.InLiveBadge inLive={event.availabilityStateLive} className="me-1" />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="mb-1 d-flex justify-content-between">
                    <Link
                      to={eventConfigurationUrl(event.id)}
                      key={`event_${event.id}`}
                      target="_blank"
                    >
                      Link to Event configuration
                    </Link>
                    {!event.recurring && get(event, 'occurrences[0].playerAllocated') && (
                      <Button.DeleteText
                        icon=""
                        title="Remove Allocation"
                        onClick={deallocate}
                        data-occurrence-id={event.occurrences[0].id}
                      />
                    )}
                  </div>
                  <dl className="row">
                    <ConfigurationRow title="Status" value={event.status} />
                    <ConfigurationRow title="Type Key" value={event.typeKey} />
                    {event.recurring || <ConfigurationRow title="Preload At" value={event.preloadAt} />}
                    <ConfigurationRow title="Start At" value={event.startAt} />
                    {event.recurring || <ConfigurationRow title="End At" value={event.endAt || '-'} />}
                    <ConfigurationRow title="Priority" value={event.priority} />
                    <ConfigurationRow title="Variant ID" value={event.variantId} />
                  </dl>
                  {event.recurring ? (
                    <PageSection title="Occurrences">
                      {event.occurrences.map((occurrence) => (
                        <Card className="mb-3">
                          <Card.Header>
                            {`#${occurrence.id} - Occurrence ${occurrence.number}`}
                            <Status value={occurrence.state} className="ms-1" />
                            {occurrence.playerAllocated && (
                              <Button.DeleteText
                                icon=""
                                className="ms-2"
                                size="sm"
                                title="Remove Allocation"
                                onClick={deallocate}
                                data-occurrence-id={occurrence.id}
                              />
                            )}
                          </Card.Header>
                          <Card.Body>
                            <dl className="row mb-3">
                              <ConfigurationRow title="Preload At" value={occurrence.preloadAt} />
                              <ConfigurationRow title="Start At" value={occurrence.startAt} />
                              <ConfigurationRow title="End At" value={occurrence.endAt} />
                              <ObjectivesTable occurrence={occurrence} />
                              <PlayerCardsTable occurrence={occurrence} refetch={refetch} />
                            </dl>
                          </Card.Body>
                        </Card>
                      ))}
                    </PageSection>
                  ) : (
                    <>
                      <ObjectivesTable occurrence={event.occurrences[0]} />
                      <PlayerCardsTable occurrence={event.occurrences[0]} refetch={refetch} />
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )
        : <EmptyList title="There are no events for the player" />}
    </>
  );
}
