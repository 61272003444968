import React from 'react';
import { Filters } from '@tripledotstudios/react-core/table';

import { useAppData } from '@hooks';
import { useEventTypesOptions } from '@pages/events/types/queries';
import { ApplyDefaultsButton, AvailabilityStateFilter, CreatedByMeFilter } from '@components/filters';

export default function Filter({ defaults }) {
  const { enumOptions } = useAppData();
  const statusOptions = enumOptions['Events::ConfigurationStatuses'];
  const { data: typeOptions } = useEventTypesOptions();

  return (
    <Filters.Container>
      <Filters.Select label="Status" name="statusIn" options={statusOptions} isMulti />
      <AvailabilityStateFilter />
      <Filters.Select label="Event type" name="typeIdIn" options={typeOptions} isMulti />
      <CreatedByMeFilter />
      <Filters.DateTime label="Starts at" name="startAt" />
      <Filters.DateTime label="Ends at" name="endAt" />
      <ApplyDefaultsButton defaults={defaults} />
    </Filters.Container>
  );
}
