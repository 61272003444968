import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { InputGroup } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useFormGroup } from '@tripledotstudios/react-core';
import { isNil } from 'lodash';

import { useFormPermissions } from '@hooks';
import Label from './Label';
import FloatingLabel from './FloatingLabel';
import Error from './Error';
import ChainLinkButton from '../buttons/ChainLinkButton';

const createStyles = (
  isMulti,
  withFloatingLabel,
  multiplier,
  alignMenu = 'left',
) => {
  const height = isMulti ? {} : { height: (withFloatingLabel ? 42 : 35) * multiplier };

  return {
    control: (base) => ({
      ...base,
      ...height,
      minHeight: (withFloatingLabel ? 42 : 35) * multiplier,
      borderColor: '#dee2e6',
    }),
    dropdownIndicator: (base) => ({ ...base, padding: 6 }),
    clearIndicator: (base) => ({ ...base, padding: 6 }),
    menu: (base) => ({
      ...base,
      width: 'max-content',
      minWidth: '100%',
      ...(alignMenu === 'right' ? {
        right: 0,
      } : {
        left: 0,
      }),
    }),
  };
};

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#3b5fad',
    primary75: '#3a24fb',
    primary50: '#7584d0',
    primary25: 'rgba(44, 86, 255, 0.20)',
  },
});

const SelectBase = ({
  name,
  options,
  onChange,
  defaultValue,
  heightMultiplier = 1,
  withFloatingLabel = false,
  disabled = false,
  isMulti = false,
  onConfirmChange = false,
  targetToRelative = false,
  alignMenu = 'left',
  ...rest
}) => {
  const { control } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();
  const bodyElement = document.querySelector('body');
  const reactSelectClass = 'react-select__menu-list';

  return (
    <Controller
      name={fullName}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value: fieldValue, onChange: onFieldChange, ...props } }) => (
        <ReactSelect
          options={options}
          isMulti={isMulti}
          isDisabled={disabled || readOnly}
          closeMenuOnScroll={(e) => !e?.target?.classList?.contains(reactSelectClass)}
          value={isMulti
            ? options.filter(({ value }) => fieldValue?.includes(value))
            : (options.find(({ value }) => (value === fieldValue) || (isNil(value) && isNil(fieldValue))) || null)}
          onChange={(option) => {
            const result = isMulti ? option.map(({ value }) => value) : option.value;
            const handleOnChange = () => {
              if (onChange) try { onChange(result); } catch (e) { console.error(e); }
              onFieldChange(result);
            };

            if (onConfirmChange) {
              onConfirmChange(result)
                .then(handleOnChange)
                .catch(() => {});
            } else {
              handleOnChange();
            }
          }}
          components={{ IndicatorSeparator: () => null }}
          styles={createStyles(isMulti, withFloatingLabel, heightMultiplier, alignMenu)}
          theme={selectTheme}
          menuPlacement="auto"
          className="react-select"
          classNamePrefix="react-select"
          {...targetToRelative ? {} : { menuPortalTarget: bodyElement }}
          {...props}
          {...rest}
        />
      )}
    />
  );
};

const Select = ({ name, ...rest }) => (
  <>
    <SelectBase name={name} {...rest} />
    <Error name={name} />
  </>
);

const SelectWithAddon = ({
  before, after, name, groupOptions = {}, type, ...rest
}) => (
  <>
    <InputGroup {...groupOptions}>
      {before && typeof before === 'string' ? <InputGroup.Text>{before}</InputGroup.Text> : before}
      <SelectBase name={name} {...rest} />
      {after && typeof after === 'string' ? <InputGroup.Text>{after}</InputGroup.Text> : after}
    </InputGroup>
    <Error name={name} />
  </>
);

const LabeledSelectWithLink = ({
  buildLinkUrl, name, label, isFloating, ...rest
}) => {
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const value = useWatch({ name: fullName });
  const LabelComponent = isFloating ? FloatingLabel : Label;

  return (
    <LabelComponent label={label}>
      <SelectWithAddon
        name={name}
        after={value ? <ChainLinkButton to={buildLinkUrl(value)} /> : null}
        {...rest}
      />
    </LabelComponent>
  );
};

const LabeledSelect = ({
  label, sizes, tooltipText, ...rest
}) => (
  <Label label={label} sizes={sizes} tooltipText={tooltipText}>
    <Select {...rest} />
  </Label>
);

const FloatingLabeledSelect = ({
  label, sizes: _sizes, tooltipText, ...rest
}) => (
  <FloatingLabel label={label} tooltipText={tooltipText}>
    <Select withFloatingLabel {...rest} />
  </FloatingLabel>
);

export {
  Select, LabeledSelect, FloatingLabeledSelect, SelectWithAddon, LabeledSelectWithLink, selectTheme,
};
