import { toOptions } from '@services/utils';
import { useQuery } from '@tanstack/react-query';
import { EventsRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';

const LIST_QUERY_NAME_KEY = 'events/types';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function eventTypesQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  extraKey = 'key',
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params.applicationId, extraKey],
    queryFn: async () => {
      const { data } = await EventsRoutes.Types.indexRequest(params);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useEventTypesOptions() {
  const { query } = useRouter();
  return useQuery(
    eventTypesQuery({ params: query, transform: (data) => toOptions(data.items), extraKey: 'options' }),
  );
}

export function useEventTypes(query = {}, opts = {}) {
  return useQuery(eventTypesQuery({
    params: { applicationId: query.applicationId, includeInUse: true, withoutPagination: true },
    ...opts,
  }));
}
