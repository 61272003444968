import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup, Alert, PageSection } from '@tripledotstudios/react-core';
import { some, sumBy } from 'lodash';

import { useAppData } from '@hooks';
import {
  LabeledSwitch,
  DiffWrapper,
  LabeledTimePeriodField,
  LabeledRadioButton,
  LabeledToggleButtonField,
  LabeledInput,
  Label,
  InputWithAddon,
} from '@components/resource';
import durationText from '@services/durationText';
import OccurrencesDetails from './OccurrencesDetails';

export default function RecurrenceSettings({ activities, activitySetOption }) {
  const { watch } = useFormContext();
  const { generateName } = useFormGroup();

  const { enums, enumOptions } = useAppData();
  const occurrenceFrequencyTypes = enums['Events::OccurrenceFrequencyTypes'];
  const [sunday, ...rest] = enumOptions['Events::OccurrenceWeekDays'];
  const weekDayOptions = [...rest, sunday];
  const frequencyType = watch(generateName('occurrenceFrequencyType'));
  const startAt = watch('startAt');
  const limitNumberOfOccurrences = watch(generateName('limitNumberOfOccurrences'));
  const occurrenceDuration = sumBy(activities, (a) => (a.duration || 0) + (a.cooldownBeforeNextActivity || 0));

  if (activities.length === 0 || some(activities, (a) => !a.duration)) {
    return (
      <Alert variant="warning">
        <div className="ms-2 d-flex align-items-center">
          <div className="flex-grow-1">
            Recurrence configuration will become available after configuring Activities durations
          </div>
        </div>
      </Alert>
    );
  }

  const cooldownBasedSettings = ({ inputId }) => (
    <Label label="Cooldown based" sizes={[12, 12]} htmlFor={inputId}>
      <DiffWrapper for="occurrenceCooldown" disabled={frequencyType !== occurrenceFrequencyTypes.COOLDOWN}>
        <LabeledTimePeriodField
          name="occurrenceCooldown"
          label="Cooldown between occurrences"
          disabled={frequencyType !== occurrenceFrequencyTypes.COOLDOWN}
          inputs={['days', 'hours', 'minutes']}
          labelProps={{ className: 'ms-5' }}
          sizes={[3, 6]}
        />
      </DiffWrapper>
    </Label>
  );

  const weeklyBasedSettings = ({ inputId }) => (
    <Label label="Weekly based" sizes={[12, 12]} htmlFor={inputId}>
      <div>
        <Label label="Every occurrence will start at" sizes={[3, 6]} className="ms-5">
          <div className="mt-1">{startAt.split('T')[1]}</div>
        </Label>
      </div>
      <DiffWrapper for="daysOfWeek" disabled={frequencyType !== occurrenceFrequencyTypes.WEEK_DAYS}>
        <LabeledToggleButtonField
          name="daysOfWeek"
          label="Start of occurence"
          options={weekDayOptions}
          type="checkbox"
          disabled={frequencyType !== occurrenceFrequencyTypes.WEEK_DAYS}
          labelProps={{ className: 'ms-5' }}
          sizes={[3, 6]}
        />
      </DiffWrapper>
      <DiffWrapper for="repeatEvery" disabled={frequencyType !== occurrenceFrequencyTypes.WEEK_DAYS}>
        <Label label="Repeat every" className="ms-5" sizes={[3, 2]}>
          <InputWithAddon
            name="repeatEvery"
            after="weeks"
            type="number"
            disabled={frequencyType !== occurrenceFrequencyTypes.WEEK_DAYS}
          />
        </Label>
      </DiffWrapper>
    </Label>
  );

  return (
    <PageSection title="Recurrence">
      <DiffWrapper for="limitNumberOfOccurrences">
        <LabeledSwitch label="Limit number of occurrences" name="limitNumberOfOccurrences" sizes={[3, 9]} />
      </DiffWrapper>
      {limitNumberOfOccurrences && (
        <DiffWrapper for="numberOfOccurrences">
          <LabeledInput
            label="Number of occurrences"
            name="numberOfOccurrences"
            type="number"
            sizes={[3, 1]}
          />
        </DiffWrapper>
      )}
      <Label label="Duration of event occurrence" sizes={[3, 9]}>
        {activitySetOption
          ? [
            `Shortest: ${durationText(activitySetOption.shortestDuration)}`,
            `Longest: ${durationText(activitySetOption.longestDuration)}`,
          ].join('; ') : durationText(occurrenceDuration)}
      </Label>
      <DiffWrapper for="occurrenceFrequencyType">
        <LabeledRadioButton
          name="occurrenceFrequencyType"
          label="Occurrence Frequency"
          LabelComponent="div"
          defaultValue={occurrenceFrequencyTypes.COOLDOWN}
          sizes={[12, 12]}
          labelProps={{ className: 'w-100 ms-2' }}
          options={[
            { value: occurrenceFrequencyTypes.COOLDOWN, label: cooldownBasedSettings },
            { value: occurrenceFrequencyTypes.WEEK_DAYS, label: weeklyBasedSettings },
          ]}
        />
      </DiffWrapper>
      {!activitySetOption && <OccurrencesDetails />}
    </PageSection>
  );
}
