import React, { memo, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';
import { useFormPermissions } from '@hooks';
import Error from './Error';
import Label from './Label';

const RadioButton = memo(({
  name, options, labelProps = {}, LabelComponent = 'label',
}) => {
  const { register, getValues } = useFormContext();
  const { generateName } = useFormGroup();
  const { readOnly } = useFormPermissions();

  const fullName = generateName(name);

  const checked = getValues(fullName);

  return (
    <>
      {options.map(
        ({ label, value }) => {
          const inputId = useId();

          return (
            <div key={value} className="d-flex align-items-baseline">
              <input
                id={inputId}
                {...register(fullName)}
                type="radio"
                value={value}
                defaultChecked={value === checked}
                disabled={readOnly}
              />
              <LabelComponent htmlFor={inputId} className="ms-1" {...labelProps}>
                {typeof label === 'function' ? label({ inputId }) : label}
              </LabelComponent>
            </div>
          );
        },
      )}
      <Error name={name} />
    </>
  );
});

const LabeledRadioButton = memo(({
  label, sizes, ...rest
}) => (
  <Label label={label} sizes={sizes}>
    <RadioButton {...rest} />
  </Label>
));

export default LabeledRadioButton;
