import { compact } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useState } from 'react';

export default function useSortableFieldArray({ keyName = 'key', ...options }) {
  const { clearErrors, control, getValues } = useFormContext();
  const {
    update,
    remove,
    replace,
    fields,
    append: originalAppend,
    ...rest
  } = useFieldArray({
    control,
    keyName,
    ...options,
  });

  const [lastPosition, setLastPosition] = useState(() => {
    const maxPosition = Math.max(...fields.map(({ position }) => position ?? -1), -1);
    return maxPosition === -1 ? 0 : maxPosition;
  });

  function getNextPosition() {
    setLastPosition((prev) => prev + 1);
    return lastPosition + 1;
  }

  function resetPositions() {
    setLastPosition(0);
    return 0;
  }

  function append(value) {
    const nextPosition = getNextPosition();
    originalAppend({ ...value, position: nextPosition });
    return nextPosition;
  }

  function handleDelete(record) {
    // Get all active fields (non-destroyed and not being deleted) in current order
    const activeFields = fields
      .filter((field) => !field._destroy && field.key !== record.key)
      .sort((a, b) => a.position - b.position);

    // Update the fields array
    const updatedFields = compact(fields.map((field) => {
      // Skip already destroyed fields
      if (field._destroy) return field;

      // Handle the field being deleted
      if (field.key === record.key) {
        return field.id ? { ...field, _destroy: true, position: -1 } : null;
      }
      // Calculate new position based on current index in activeFields
      const newIndex = activeFields.findIndex((f) => f.key === field.key);
      return { ...field, position: newIndex + 1 };
    }));

    const sortedFields = updatedFields.sort((a, b) => a.position - b.position);

    replace(sortedFields);
    setLastPosition(activeFields.length);
    clearErrors();
  }

  function handleDragEnd({ active, over }) {
    if (!over || active.id === over.id) return;

    const formValues = getValues(options.name);
    const activeItem = fields.find(({ key }) => key === active.id);
    const overItem = fields.find(({ key }) => key === over.id);

    if (!activeItem || !overItem) return;

    const oldPosition = activeItem.position;
    const newPosition = overItem.position;

    // Create map of current form values
    const valuesByKey = Object.fromEntries(
      fields.map((field, index) => [field.key, formValues[index]]),
    );

    // Update positions for all affected items
    const updatedItems = fields.map((item) => {
      const currentValues = valuesByKey[item.key];

      // Item being dragged
      if (item.key === active.id) {
        return { ...item, ...currentValues, position: newPosition };
      }

      // Items between old and new position need their positions adjusted
      if (oldPosition < newPosition) {
        // Moving down - decrease positions of items in between
        if (item.position <= newPosition && item.position > oldPosition) {
          return { ...item, ...currentValues, position: item.position - 1 };
        }
      } else if (item.position >= newPosition && item.position < oldPosition) {
        // Moving up - increase positions of items in between
        return { ...item, ...currentValues, position: item.position + 1 };
      }

      // Items outside the range stay the same
      return { ...item, ...currentValues };
    });

    // Sort by position and update
    replace(updatedItems.sort((a, b) => a.position - b.position));
    clearErrors();
  }

  const sortedFields = fields.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));

  return {
    fields: sortedFields,
    rawFields: fields,
    update,
    remove,
    replace,
    append,
    handleDelete,
    handleDragEnd,
    getNextPosition,
    resetPositions,
    lastPosition,
    ...rest,
  };
}
