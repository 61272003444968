import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Form, FormContainer, ImageSquare } from '@components';
import { useRouter, PageSection } from '@tripledotstudios/react-core';
import {
  LabeledInput,
  LabeledSelect,
  LabeledCheckbox,
  RegisteredError,
} from '@components/resource';
import { useQuery } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';

import GameItems from './GameItems';

const ImagesRoutes = PaymentsRoutes.ProductImages;
const GameItemsRoutes = PaymentsRoutes.GameItems;

export default function GameProductForm({ resource, ...rest }) {
  const { query } = useRouter();

  const { response: images } = useQuery(ImagesRoutes.indexRequest, { ...query, withoutPagination: true });
  const { response: gameItems } = useQuery(
    GameItemsRoutes.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );

  if (!images?.items || !gameItems?.items) {
    return null;
  }

  const imageOptions = [
    { label: 'No Product Image', value: '' },
    ...images.items.map(({ id, name, fileUrl }) => ({ label: name, value: id, fileUrl })),
  ];
  const gameItemOptions = gameItems.items.map(({ id, name }) => ({ label: name, value: id }));
  const imageLabelFormat = ({ label, fileUrl }) => (
    <>
      {label}
      {fileUrl && <ImageSquare className="ms-2" size="30px" src={fileUrl} />}
    </>
  );

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ watch }) => {
        const imageUrl = images.items.find(({ id }) => id === watch('imageId'))?.fileUrl;

        return (
          <Row>
            <FormContainer>
              <RegisteredError name="base" />
              <LabeledInput disabled label="Name" name="name" />
              <LabeledInput disabled label="External Id" name="externalId" />
              <LabeledInput disabled label="Deleted" name="deleted" />
              <LabeledCheckbox disabled label="Ad Product" name="adProduct" />
              <LabeledInput disabled label="Created At (UTC)" name="createdAt" />
              <LabeledInput disabled label="Updated At (UTC)" name="updatedAt" />
              <LabeledSelect
                label="Image"
                name="imageId"
                options={imageOptions}
                formatOptionLabel={imageLabelFormat}

              />
              <GameItems gameItemOptions={gameItemOptions} permissions={resource.data.permissions} />
            </FormContainer>
            <Col className="col-xxxl-6 col-xxl-4 col-xl-2 col-lg-12">
              {imageUrl && (
                <PageSection title="Product Image">
                  <ImageSquare size="25rem" src={imageUrl} />
                </PageSection>
              )}
            </Col>
          </Row>
        );
      }}
    </Form.Resource>
  );
}
